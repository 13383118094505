import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import config from "../config";
import firebaseConfig from "./firebaseConfig.json"

const initializeFirebase = () => {

  if (!firebase.apps.length) {
    //const firebaseConfig = fs.readFile(config.firebaseConfig);
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }
  firebase.auth().useDeviceLanguage();
};

const analytics = firebase.analytics;

export { firebase, initializeFirebase, analytics };
