export default {
  env: process.env.NODE_ENV,
  mode: process.env.MODE,
  serverApi: process.env.SERVER_API,
  amityApiKey:process.env.AMITY_API_KEY,
  amityServerKey:process.env.AMITY_X_SERVER_KEY,
  firebaseConfig: process.env.FIREBASE_CONFIG,
  amptitudeApiKey:process.env.AMPTITUDE_API_KEY,
  dynamicLinks: {
    domainUriPrefix: process.env.DOMAIN_URI_PREFIX_DYNAMIC_LINKS,
    androidPackageName: process.env.ANDROID_PACKAGE_NAME,
    iosBundleId: process.env.IOS_BUNDLE_ID,
  }
}
