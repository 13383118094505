import axios from "axios";
import config from "config";
import { authStorage } from "services/authStorage";
import { axiosInstance } from ".";
import { firebase } from "./firebase";

let token = authStorage.token;

const reportError = async (errorData) => {
    const headers = token
        ? { Authorization: `Bearer ${token}` }
        : {};
    const requestData = {
        errorMessage: errorData?.message ?? '',
        page: errorData?.page ?? '',
        errorData: errorData?.errorData ?? {},
    };

    try {
        let apiUrl = "";
        const response = await axios.post(apiUrl, requestData, { headers });
        return response;
    } catch (error) {
        console.error('Error reporting failed: ', error);
        return null;
    }
};

const updateToken = async () => {
    if (firebase.auth().currentUser) {
        try {
            const idToken = await firebase.auth().currentUser.getIdToken();
            if (token !== idToken) authStorage.token = idToken;
            token = idToken;
        } catch (error) {
            console.error('Failed to update token: ', error);
        }
    }
};

export { reportError, updateToken };
