import cookie from "react-cookies";
import axios from "axios";

const COOKIE_STORAGE = {
  token: "token",
  customToken: "customToken",
};

const isServer = typeof window === "undefined";

const localAxiosInstance = axios.create({
  baseURL: "/api/",
});

class AuthStorage {
  constructor() {}
  set token(value) {
    if (this.token === value) return;
    localAxiosInstance.post("setCookie", {
      name: COOKIE_STORAGE.token,
      value,
    });
    localStorage.setItem(COOKIE_STORAGE.token, value);
  }
  get token() {
    let value = isServer
      ? cookie.load(COOKIE_STORAGE.token)
      : localStorage.getItem(COOKIE_STORAGE.token);
    return value;
  }

  set customToken(value) {
    if (this.customToken === value) return;
    localAxiosInstance.post("setCookie", {
      name: COOKIE_STORAGE.customToken,
      value,
    });
    localStorage.setItem(COOKIE_STORAGE.customToken, value);
  }
  get customToken() {
    let value = isServer
      ? cookie.load(COOKIE_STORAGE.customToken)
      : localStorage.getItem(COOKIE_STORAGE.customToken);
    return value;
  }

  destroy() {
    localAxiosInstance.post("setCookie", {
      name: COOKIE_STORAGE.customToken,
      value: "",
    });
    localAxiosInstance.post("setCookie", {
      name: COOKIE_STORAGE.token,
      value: "",
    });
    //
    localStorage.removeItem(COOKIE_STORAGE.customToken);
    localStorage.removeItem(COOKIE_STORAGE.token);
  }
}
const authStorage = new AuthStorage();
export { authStorage };
